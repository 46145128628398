// import { getInfo } from '@/api/user'
// import { postSignIn } from '@/api/login'
// import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    // token: getToken(),s
    active: 1,
  }
}

const state = getDefaultState()

const mutations = {
  SET_ACTIVE: (state, active) => {
    state.active = active
    sessionStorage.setItem('a',active)
  },
}

const actions = {
  // user login
 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

