<template>
  <div>
    <router-view />
  </div>
    
</template>

<script>  
export default {
  name: 'App',
  components: {
    // ScaleBox 
  },
  data() {
    return {
     
    }
  },
  created() {
  },
}
</script>

<style lang='scss'>

      @media (min-width: 768px) {

        .media_title {
            height: 188px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p:nth-child(1) {
                color: #333333;
                font-size: 30px;
            }

            p:nth-child(2) {
                font-size: 14px;
                color: #999999;
            }

            p:nth-child(3) {
                width: 68px;
                height: 4px;
                background-color: #4C80E7;
                margin-top: 10px;
            }
        }
        
      }
      @media (max-width: 768px) {
        .media_title {
            margin: 20px 0 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p:nth-child(1) {
              font-family: Source Han Sans SC;
              font-weight: 400;
              font-size: 15px;
              color: #333333;
              line-height: 28px;
              margin: 0;
            }

            p:nth-child(2) {
              font-family: Adobe Heiti Std;
              font-weight: normal;  
              font-size: 8px;
              color: #999999;
              // line-height: 28px;
              // margin-top: 8px;
            }

            // p:nth-child(3) {
            //     width: 68px;
            //     height: 4px;
            //     background-color: #4C80E7;
            //     margin-top: 10px;
            // }
        }
      }

</style>
