import Vue from 'vue'
import App from './App.vue'
// import 'lib-flexible/flexible'
// 引入全局CSS文件
import './assets/style/style.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
import router from './router'
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// const router = new VueRouter({
//   routes: routes // （缩写）相当于 routes: routes
// });

router.beforeEach((to, from, next) => {
  // 可以在这里根据路由的meta字段或其他条件进行判断
  // ...
  const dataLidt=[
    {
        title:'首页',
        router:'/home',   
        id:1,
    },{
        title:'透明惠民',
        router:'/transparent',
        id:2,
    },{
        title:'失信筹款人黑名单',
        router:'/blacklist',
        id:3,
    },{
        title:'捐赠公示',
        router:'/publicity',
        id:4,
    },{
        title:'关于我们',
        router:'/aboutUs',
        id:5,
    }
]
 console.log(to,from,'=====>');
 dataLidt.forEach((item,i)=>{
  if(item.router === to.path){
    // console.log(item.router,to.path,'router');
    store.commit('user/SET_ACTIVE', item.id)
  }
 })
  // 必须调用next()来resolve这个钩子
  next();
});

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({  
  router,
  store,
  render: h => h(App),
}).$mount('#app')
