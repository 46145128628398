<template>
  <div class="container">
    <header class="main_center">
        <div class="top_tab" v-if="active!==1">
            <p class="top_logo">
                <img src="../assets/images/logo_h.png" alt="" srcset="">
            </p>
            <div class="topList">
                <p v-for="(item,i) in dataLidt" :key="i" :class="[i+1 === active ? 'active_item' : '']" @click="handleClickRouter(item)">
                    {{ item.title }}
                </p>
            </div>
        </div>
        <div class="top_tab_h5">
            <p class="top_logo">
                <img src="../assets/images/logo_h.png" alt="" srcset="">
            </p>
            <div class="topList_phone">
                <p>
                    <!-- <p v-for="(item,i) in dataLidt.slice(0,1)" :key="i" :class="[i+1 === active ? 'active_item' : '']" @click="handleClickRouter(item)">
                        {{ item.title }}
                    </p> -->
                    <el-dropdown trigger="click" @command="handleClickRouter">
                        <span class="el-dropdown-link">
                            <svg style="margin: 2px 0 0 6px;" t="1722492517223" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4258" width="18" height="18"><path d="M892.928 128q28.672 0 48.64 19.968t19.968 48.64l0 52.224q0 28.672-19.968 48.64t-48.64 19.968l-759.808 0q-28.672 0-48.64-19.968t-19.968-48.64l0-52.224q0-28.672 19.968-48.64t48.64-19.968l759.808 0zM892.928 448.512q28.672 0 48.64 19.968t19.968 48.64l0 52.224q0 28.672-19.968 48.64t-48.64 19.968l-759.808 0q-28.672 0-48.64-19.968t-19.968-48.64l0-52.224q0-28.672 19.968-48.64t48.64-19.968l759.808 0zM892.928 769.024q28.672 0 48.64 19.968t19.968 48.64l0 52.224q0 28.672-19.968 48.64t-48.64 19.968l-759.808 0q-28.672 0-48.64-19.968t-19.968-48.64l0-52.224q0-28.672 19.968-48.64t48.64-19.968l759.808 0z" p-id="4259"></path></svg>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item,i) in dataLidt" :key="i" :command="item"> {{ item.title }}</el-dropdown-item>
                        </el-dropdown-menu>
                        </el-dropdown>
                   
                </p>
            </div>
        </div>
    </header>
    <main class="mian_bg">
        <router-view />
        <footer class="footer_banner">
        <div class="footermain">
            <img class="footermain_logo" src="../assets/images/logo_h_i.png" alt="" srcset="">
            <p class="footermain_list">
                <span v-for="(item,i) in dataLidt" :key="i" @click="handleClickRouter(item)">
                    {{ item.title }}  <span class="sp">{{dataLidt.length-1 == i ?'' : '|' }}</span>  
                </span>
            </p>
            <p class="footer_main">
               <span>
                   暖心惠民  <span @click="openNewpage"> 备案号：京ICP备2022019858号-1</span>
               </span>  
               <span>
                  <span @click="openNewpage"> ICP证书：京B2-20223566 </span>
               </span>
               <span> 
                    服务电话：400-675-1213
               </span>
            </p>
            <div class="footer_main_h5">
                <p>
                    开发者信息：北京暖心惠民科技有限公司
                </p>
               <p>
                  <span @click="openNewpage"> 备案号：京ICP备2022019858号-1</span>
               </p>  
               <p>
                <span @click="openNewpage"> ICP证书：京B2-20223566 </span>
               </p>
               <p>
                <span> 热线电话：400-675-1213 </span>
               </p>
            </div>
        </div>
    </footer>
    </main>
      
    
  </div>
</template>

<script>
 export default {    
    data(){
        return{
            dataLidt:[
                {
                    title:'公司首页',
                    router:'/home',   
                    id:1,
                },{
                    title:'知暖心行惠民',
                    router:'/transparent',
                    id:2,
                },{
                    title:'失信求助人黑名单',
                    router:'/blacklist',
                    id:3,
                },
                // {
                //     title:'捐赠公示',
                //     router:'/publicity',
                //     id:4,
                // },
                {
                    title:'关于我们',
                    router:'/aboutUs',
                    id:5,
                }
            ],
            active: 1,
        }
    },
    watch:{
        '$store.state.user.active'(newval,oldval){
            console.log(newval,oldval);
            this.active=newval
        }
    },
    mounted(){
       this.active=Number(sessionStorage.getItem('a')) || 1
       console.log(this.active);
       this.$store.commit('user/SET_ACTIVE', this.active)
    }, 
    methods:{
        handleClickRouter(item){
            console.log(item,'111');
            this.active=item.id
            this.$store.commit('user/SET_ACTIVE', this.active)
            this.$router.push(item.router)
        },
        openNewpage(){
            window.open('https://beian.miit.gov.cn/#/Integrated/index')
        }
    }
}
</script>

<style lang="scss" scoped> 

    @media (min-width: 768px) {
        .container{
            // width: 100%;
            height: 100%;
            min-width: 1200px;
            overflow: hidden;
            touch-action: none;
            .main_center{
                width: 100%;      
                height: 100%;
                margin: 0 auto;
                .top_tab{
                    width: 100%;
                    padding: 0 50px;
                    height: 80px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .top_logo{
                        img{
                            width: 150px;
                        }
                    }
                    .topList{
                        width: 600px;
                        justify-content: space-around;
                        display: flex;
                        align-items: center;
                        p{
                        // padding-left: 40px;
                        cursor: pointer;
                        color: #999;
                        // padding-bottom: 6px;
                        // line-height: 30px;
                        height: 30px;
                        }
                        .active_item{
                            color: #000;
                            border-bottom: 2px solid #2097FF;
                        }
                    }
                    .topList_phone{
                        display: none;
                    }
                }
                .top_tab_h5{
                    display: none;
                }
            }
            .footer_banner{
                width: 100%;
                background-color: #4C80E7;
                height: 240px;
                .footermain{
                    width: 1200px;
                    margin: 0 auto;
                    .footermain_logo{
                        width: 171px;
                        margin-top: 47px;
                    }
                    .footermain_list{
                        width: 100%;
                        display: flex;
                        margin-top: 32px;
                        margin-bottom: 10px;

                        color: #fff;
                        .sp{
                            padding: 0 10px;
                            line-height: 30px;
                        }
                    }
                    .footer_main{
                        color: #fff;
                        font-family: HP Simplified Hans;
                        font-weight: 400;
                        line-height: 21px;
                        font-size: 14px;
                        span{
                            margin-right: 20px;
                        }
                    }
                    .footer_main_h5{
                        display: none;
                    }
                }
            }     
        }
    }
    @media (max-width: 768px) {
        .container{
            width: 100%;
            height: 100%;
            overflow: hidden;
            // touch-action: none;
            .mian_bg{
                width: 100%;
                height: calc(100vh - 60px);
                overflow-y: scroll;
            }
            .main_center{
                width: 100%;
                height: 60px;
                margin: 0 auto;
                .top_tab{
                    display: none;
                }
                .top_tab_h5{
                    width: 100%;
                    height: 60px;
                    margin: 0 auto;
                    padding: 16px 10px 0;
                    display: flex;
                    // align-items: center;
                    justify-content: space-between;
                    .top_logo{
                        img{
                            width: 90px;   
                        }
                    }
                    .topList_phone{
                        width: calc(100% - 120px);
                        justify-content: end;
                        display: flex;
                        p{
                            font-size: 18px;
                            cursor: pointer;
                            color: #333333;
                            height: 40px;
                        }
                        .el-dropdown-link{
                            display: flex;
                            font-size: 18px;
                            cursor: pointer;
                            color: #333333;
                            height: 40px;
                        }
                        .active_item{
                            color: #4C80E7;
                            border-bottom: 3px solid #4C80E7;
                        }
                    }
                }
            }
            .footer_banner{
                width: 100%;
                background: #333333;
                height: 116px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .footermain{
                    margin: 0 auto;
                    width: 100%;
                    .footermain_logo{
                        display: none;
                    }
                    .footermain_list{
                        display: none;
                    }
                    .footer_main{
                       display: none;  
                    }
                }
                .footer_main_h5{
                    color: #999;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 12px;
                    p{
                        color: #999;
                    }
                }
            }  
        }   
    }

</style>